*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}

body{
    font-family: 'Source Code Pro', monospace;
}
a.link {
    color: rgba(255, 255, 255, 0.5);
}
hr {
    margin-bottom: 50px;
    color: rgba(255, 255, 255, 0.5);
}
.foot {
    background: black;
    
}
h5 {
    margin-bottom: 50px;
    color: aliceblue;
}
address {
    color: rgba(255, 255, 255, 0.5);

}
.btn-social-icon {
    color: rgba(255, 255, 255, 0.5);
}

a.btn-facebook :hover {
    color: rgb(46, 0, 253);
}

a.btn-linkedin :hover {
    color: #0e76a8;
}

a.btn-twitter :hover {
    color: #1DA1F2;
}
a.btn-youtube :hover {
    color: #FF0000;
}
a.btn-mail :hover {
    color: #0F9D58
}

p.copy {
    color: aliceblue;
}
.icon-circle.blue{
    color: #fff;
    text-align: center;
  }
  .icon-circle .icon{
    margin: 0 auto;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: ease-in-out 0.3s;
    color: white;
  }
  .icon-circle .icon i{
    font-size: 35px;
    margin: 7px;
  }
  .services .icon-circle h4{
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 28px;
  }
  .services .icon-circle p{
    line-height: 24px;
    font-size: 14px;
  }