.thead{
    margin-bottom: 2em;
    border: 1px solid black;
}

.table{
    margin: 2em 2px;
    border: 1.5px solid black;
}

.h2{
    margin-top: 2em;
}