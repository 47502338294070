.main-image{
    padding: 10px;
}
.text{
    font-weight: 800;
    font-size: larger;
}
.heading h1{
    margin-top: 10px;
}
.formsss h3{
    text-align: left;
    margin-top: 20px;
    font-size: larger;
}
.formsss h5{
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
}
.formsss h4{
    text-align: left;
    margin-top: 20px;
    font-weight: 600;
}
form{
    text-align: left;
    margin: 10px;
}