thead{
    margin-bottom: 2em;
    border: 1px solid black;
}

table {
    border-collapse: collapse;
    padding: 15%;
    width: 80%;
  }
  
  .Th, .Td {
    text-align: center;
    padding: 5px;
  }
  
  .Tr:nth-child(even){background-color: #dddcdc}
  
  .Th {
    background-color: black;
    color: white;
    padding: 10px;
    font-size: 1.5rem;
  }
  