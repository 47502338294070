table {
  border-collapse: collapse;
  padding: 15%;
  width: 80%;
}

th, td {
  text-align: center;
  padding: 5px;
}

tr:nth-child(even){background-color: #dddcdc}

th {
  background-color: black;
  color: white;
  padding: 10px;
  font-size: 1.5rem;
}
