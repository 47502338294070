thead{
    margin-bottom: 2em;
    border: 1px solid black;
}

table{
    margin: 20px 20px;
    border: 1.5px solid black;
    width: 100%;
}

h2{
    margin-top: 2em;
}