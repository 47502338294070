button{
    margin: 1rem;
    font-weight: 600;
    margin-top: 0;
    color: #2f2d2e;
    font: bold;
    color: white;
    background: black ;
    padding: 0.6rem;
    border-radius: 6px;
    transition-duration: 0.5s;
    animation: ease-in-out;
    border: 2px solid black;
    font-size: 1.5rem;
    text-align: center;
    display:inline-table;
    justify-content: center;
}
.container{
    text-align: center;
}

  button:hover{
    transform:scale(1.2);
    border: none;
}


h1{
    text-align: center;
    font-size: 2rem;
    font-weight: 800;
    margin-top: 1em;
    margin-bottom: 1em;
}
.box {
    margin: 10px;
    box-shadow: 0px 1px 10px 1px black;
    border-radius: 20px;
    height: auto;
    width: 10rem;
    background: linear-gradient(to top, #f5efef 0% ,#e5edbf 100%);
}
  
.grid {
   min-height: 100%;
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
}

.image{
    height: 20rem;
    width: auto;
    border: 1px solid black;
    padding:10px ;
    border-radius: 20px;
}

.title{
    margin: 0.5rem;
    color: black;
    font-weight: 1000;
    font-size: 1.2rem;
    text-align: center;

}
.description{
    color: #2f2d2e;
    font-size: 1rem;
    text-align: center;
}
.footer{
    font-size: 1rem;
    text-align: center;
    background-color: #f2efe7;
    font-weight: bold;
}
.zone{
    color: #2f2d2e;
    font-size: 1rem;
    text-align: center;
    font-weight: 600;
}
.class-title{
    text-align: center;
    font-size: 2rem;
    font-weight: 800;
    margin: 1rem;
    text-shadow: 5px 5px 5px lightslategray;
}
.App {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
    
